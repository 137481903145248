import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [username, setUsername] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const parentRef = useRef();

  useEffect(() => {
    let scrollPosition = 0;

    const handleFocus = () => {
      scrollPosition = window.scrollY;
      document.body.style.overflow = 'hidden'; 
    };

    const handleBlur = () => {
      document.body.style.overflow = '';  
      window.scrollTo(0, scrollPosition); 
    };

    const inputs = parentRef.current.querySelectorAll("input, textarea");
    inputs.forEach((input) => {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      });
    };
  }, []);

  const handleSend = (e) => {
    e.preventDefault();
    const templateParams = {
      name: username,
      email: email,
      phonenumber: phonenumber,
      to_name: "Barking cat",
      message: message,
    };

    if (username === "") {
      setErrMsg("Full name is required!");
      setTimeout(() => {
        setErrMsg(null);
      }, 2000);
    } else if (phonenumber === "") {
      setErrMsg("Phone number is required!");
      setTimeout(() => {
        setErrMsg(null);
      }, 2000);
    } else if (email === "") {
      setErrMsg("Please give your Email!");
      setTimeout(() => {
        setErrMsg(null);
      }, 2000);
    } else if (message === "") {
      setErrMsg("Message is required!");
      setTimeout(() => {
        setErrMsg(null);
      }, 2000);
    } else {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result);
            setSuccessMsg(
              `Thank you dear ${username}, Your Messages has been sent Successfully!`
            );
            setTimeout(() => {
              setSuccessMsg(null);
            }, 5000);
          },
          (error) => {
            console.error("Failed to send email: ", error);
            setErrMsg("Something went wrong, please try again later");
            setTimeout(() => {
              setErrMsg(null);
            }, 5000);
          }
        );
      e.target.reset();

      setErrMsg("");
      setUsername("");
      setPhonenumber("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <div
      ref={parentRef}
      className="md:h-[600px] h-[900px] flex flex-col items-start gap-2.5 bg-[#fbfeff] px-4 py-[100px] md:px-10 lg:px-[40px] lg:py-[100px]"
    >
      <div className="flex flex-col items-start gap-6">
        <div className="flex flex-col items-start gap-4">
          <div className="text-[#201a1e] font-['Epilogue'] text-xl font-bold leading-[normal]">
            Let's Vibe
          </div>
          <div className="text-[#201a1e] font-['Epilogue'] text-lg md:text-[2.5rem] font-light leading-[130%]">
            Got buzzing ideas or popping questions? Reach out and let’s make magic together!
          </div>
        </div>
        <form className="flex flex-col items-start w-full" onSubmit={handleSend}>
          <div className="flex items-start justify-between flex-col md:flex-row w-full">
            <div className="flex items-start md:w-[50%] border-t-[0.8px] border-t-[#9e9898] border-b-[0.8px] border-b-[#9e9898] flex-col w-full max-w-xl flex-1">
              <div className="flex items-start gap-1 md:gap-3 pt-[0.9375rem] pb-[0.9375rem] px-0 w-full justify-between border-b-[0.8px] border-b-[#9e9898]">
                <label
                  htmlFor="name"
                  className="w-[120px] md:w-[150px] text-[#201a1e] font-['Epilogue'] leading-[normal]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full focus:outline-none  md:ml-1 md:pl-0 text-[#201a1e] font-['Epilogue'] leading-[normal]  flex-1"
                  placeholder="Your Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-start gap-1 md:gap-3 pt-[0.9375rem] pb-[0.9375rem] px-0 w-full justify-between border-b-[0.8px] border-b-[#9e9898]">
                <label
                  htmlFor="email"
                  className="w-[120px] md:w-[150px] text-[#201a1e] font-['Epilogue'] leading-[normal]"
                >
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full focus:outline-none md:pl-1 text-[#201a1e] font-['Epilogue'] leading-[normal] flex-1"
                  placeholder="Your E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-start gap-1 md:gap-3 pt-[0.9375rem] pb-[0.9375rem] px-0 w-full justify-between ">
                <label
                  htmlFor="phone"
                  className="text-[#201a1e] w-[120px] md:w-[150px] font-['Epilogue'] leading-[normal]"
                >
                  Phone number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full focus:outline-none md:pl-1 text-[#201a1e] font-['Epilogue'] leading-[normal] flex-1"
                  placeholder="Your Phone number"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-start self-stretch py-3 px-0 md:ml-5 md:border-t-[0.8px] border-t-[#9e9898] border-b-[0.8px] border-b-[#9e9898] flex-1  gap-1 md:gap-3 pt-[0.9375rem] pb-[0.9375rem]  w-full justify-between ">
              <label
                htmlFor="message"
                className="text-[#201a1e] w-[120px] md:w-[150px] font-['Epilogue'] leading-[normal]"
                >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                className="w-full focus:outline-none md:pl-1 text-[#201a1e] font-['Epilogue'] leading-[normal] flex-1   md:sh-full"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
          </div>
          <button
            className="rounded-full w-full bg-[#201a1e] text-[#e2e6e7] font-['Epilogue'] font-semibold leading-[normal] md:mt-10 mt-16 py-5 px-2"
            type="submit"
          >
            Send message
          </button>
        </form>
        {errMsg && <p className="text-red-500 text-center mt-4">{errMsg}</p>}
        {successMsg && <p className="text-green-500 text-center mt-4">{successMsg}</p>}
      </div>
    </div>
  );
};

export default Contact;
