import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../../assets/svg/logo.svg";

const Footer = () => {
  const [scrolled, setScrolled] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscribe`,
        { email }
      );
      setMessage(response.data.message);
      setSubscribed(true);
      setError(false);
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setError(true);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div
      className={`  md:h-screen h-[990px]  pb-4 md:pb-8 lg:pb-[1.5625rem] px-4 md:px-10 lg:px-12 relative ${
        scrolled
          ? "bg-[#201A1EE5] backdrop-blur-sm "
          : " bg-[#201A1ECC] bg-opacity-100 "
      } z-40 `}
    >
      <div className="flex pt-[12.5625rem] md:pt-[4.5625rem] flex-col md:flex-row  justify-between  ">
        <div className="md:w-[70%]">
          <div className="flex flex-col gap-0 md:flex-row justify-between">
            <div className="frame_47  flex-col flex-shrink-0 items-start justify-between ">
              <div className="flex   flex-col md:mt-5 items-start gap-6">
                <div className="text-white font-['Epilogue'] font-bold leading-[normal]">
                  Join us and Subscribe to our Newsletter!
                </div>
                <div className="w-full">
                  {subscribed ? (
                    <p className="text-white">
                      You've subscribed to our newsletter!
                    </p>
                  ) : (
                    <form
                      onSubmit={handleSubmit}
                      className="flex justify-between items-center w-full self-stretch border-t-[0.8px] border-t-[#9e9898] border-b-[0.8px] border-b-[#9e9898]"
                    >
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="text-white font-['Epilogue'] leading-[normal] w-full h-full pt-[0.9375rem] pb-[0.9375rem] px-2 bg-opacity-0 bg-transparent focus:outline-1 outline-none focus:ouline-[#9e9899]"
                        placeholder={error ? message : "Youremail@example.com"}
                      />
                      <button
                        type="submit"
                        disabled={loading}
                        className="ml-2 bg-transparent border-none outline-none cursor-pointer"
                      >
                        {loading ? ( 
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0120 12h4zm-6-7.291V0c-3.042 0-5.824 1.135-7.938 3l2.647 3A7.962 7.962 0 0114 4zm2 16a7.962 7.962 0 01-2.647-3l-3 2.647A7.962 7.962 0 0018 20h4zM8.938 5A7.962 7.962 0 004 12h4c0-3.042 1.135-5.824 3-7.938l-2.647-3zM16 19.063A7.962 7.962 0 0019.063 16H20c0 3.042-1.135 5.824-3 7.938l-2.647-3z"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 13L16.17 13L10.58 18.59L12 20L20 12L12 4L10.59 5.41L16.17 11L4 11L4 13Z"
                              fill="#E2E6E7"
                            />
                          </svg>
                        )}
                      </button>
                    </form>
                  )}
                </div>

              </div>
              <div className="flex flex-col   justify-between mt-4 items-start pb-5 h-[15.6875rem] ">
                <div className="flex  flex-col  mt-5 gap-3 items-start ">
                  <div className="text-white font-['Epilogue'] font-bold leading-[normal]">
                    Address
                  </div>
                  <div className=" text-[#e2e6e7] font-['Epilogue'] font-light leading-[normal]">
                    <p>No. 19 Nii Adjei Onano street,</p>
                    <p> East Legon, Ghana</p>
                  </div>
                </div>
                <div className="frame_38 flex flex-col gap-3 mt-4 items-start">
                  <div className="text-white font-['Epilogue'] font-bold leading-[normal]">
                    Email
                  </div>
                  <div className="text-white font-['Epilogue'] leading-[normal]">
                    info@barkingcat.com
                  </div>
                </div>
                <div className="flex flex-col items-start mt-5 gap-4">
                  <div className="text-white font-['Epilogue'] font-bold leading-[normal]">
                    Phone
                  </div>
                  <div className="text-[#e2e6e7] font-['Epilogue'] font-light leading-[normal]">
                    +233 200 894 547
                  </div>
                </div>
              </div>

            </div>

            <div className="flex flex-col md:pt-5 flex-shrink-0 items-start gap-5">
              <a
                href="https://www.instagram.com/barkingcat.africa?igsh=MWI5MHlzY3d6dmxmNQ%3D%3D&utm_source=qr"
                className="flex justify-center items-center gap-2.5 self-stretch py-[0.8rem] px-5 w-[100%] rounded-full border-[0.8px] border-[#9e9898] text-[#e2e6e7] font-['Epilogue'] leading-[normal]"
              >
                <div>Instagram</div>
              </a>
              <a
                href="https://x.com/barkingcat_af"
                className="flex justify-center items-center gap-2.5 self-stretch py-[0.8rem] px-5 rounded-full border-[0.8px] border-[#9e9898] text-[#e2e6e7] font-['Epilogue'] leading-[normal]"
              >
                <div>Twitter</div>
              </a>
              <a
                href="https://www.tiktok.com/@barkingcat.africa?_t=8nZpY67EVLq&_r=1"
                className="flex justify-center items-center gap-2.5 self-stretch py-[0.8rem] px-5 rounded-full border-[0.8px] border-[#9e9898] text-[#e2e6e7] font-['Epilogue'] leading-[normal]"
              >
                <div className="w-[200px] text-center">Tiktok</div>
              </a>
            </div>
          </div>
          <div className="md:flex hidden items-center text-center mt-10 pt-5 self-stretch text-5xl sm:text-6xl">
            <img src={Logo} alt="Logo" className="h-[100px] w-[111]" />

          </div>
        </div>

        <div className="flex md:flex-col  pt-8 md:pt-0  flex-row-reverse  flex-shrink-0 justify-between relative items-start  md:h-[530px] ">
          <div className="frame_29 flex flex-row md:flex-col items-start">
            <div className="flex flex-col md:pt-6 items-start gap-3 relative md:left-0 ">
              <div className="text-[#e2e6e7] font-['Epilogue']  text-sm font-light leading-[normal]">
                Privacy Policy
              </div>
              <div className="text-[#e2e6e7] font-['Epilogue'] text-sm font-light leading-[normal]">
                Terms of Service
              </div>
            </div>
          </div>
          <div className="frame_45 flex flex-col items-start self-stretch">
            <div className="frame_26-1 flex  md:pb-5 flex-col gap-3 items-start">
              <div className="text-[#e2e6e7] font-['Epilogue'] text-sm font-light leading-[normal]">
                BarkingCat 2024
              </div>
              <div className="text-[#e2e6e7] font-['Epilogue'] text-sm font-light leading-[normal]">
                All rights reserved
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="md:hidden flex items-center text-center mt-5 self-stretch text-5xl sm:text-6xl">
        <img src={Logo} alt="Logo" className="h-[100px] w-[111]" />
      </div>
    </div>
  );
};

export default Footer;
