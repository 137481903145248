import React from 'react'

const WhatWeDo = () => {
  return (
<div className="inline-flex flex-col items-start px-5 md:px-10 lg:px-[40px]  pt-[100px] gap-4 ">
  <div className="text-[#e2e6e7] font-['Epilogue'] text-xl font-bold leading-[normal]">What we do</div>
  <div className=" text-[#9e9898] font-['Epilogue']  text-2xl md:text-2xl lg:text-4xl font-light leading-[130%]">Dive into our range of services, from commercial and marketing consultancy to cross-brand collaborations and sponsorship magic.</div>
</div>  )
}

export default WhatWeDo