import React from 'react'
import WhatWeDo from './WhatWeDo'
import { Element } from 'react-scroll'
import Contact from '../Contact/Contact'
import Footer from '../footer/Footer'
import Servicess from './Servicess'

const Services = () => {
  return (
    <>
      <div className='bg-[#201A1E]'>
        <WhatWeDo />
        <Servicess />


        <div className="sticky top-0 z-30">
          <Element name="contact">
            <Contact />
          </Element>

        </div>
        <Footer />

      </div>

    </>
  )
}

export default Services