import React from 'react';
import First from "../../assets/videos/first.mp4";
import Thirdd from "../../assets/videos/service.webm";
import Fourth from "../../assets/videos/fourth.mp4";
import Fifth from "../../assets/videos/fifth.mp4";
import Thirddd from "../../assets/pictures/thirddd.jpeg";
import "../../App.css"

const Services = () => {
    const ServicesData = [
        {
            title: "Commercial Consultancy",
            subtitle: "Facilitating partnerships that amplify market presence",
            messageOneTitle:"For Talents:",
            messageOne: " Providing strategic advice on career development, brand collaborations, and market positioning.",
            messageTwoTitle:"For IP Holders:",
            messageTwo: " Offering guidance on monetizing intellectual properties and maximizing their commercial potential.",
            messageThreeTitle:"For Brands:",
            messageThree: " Creating a platform to recognize, appreciate and empower brand managers",
            asset: First,
            assetType: 'video'
        },
        {
            title: "Marketing Consultancy",
            subtitle: "Personalized marketing plans and campaigns to increase exposure and engagement.",
            messageOneTitle: "For Talents:",
            messageOne: " Crafting personalized marketing plans to increase talent exposure and engagement.",
            messageTwoTitle: "For IP Holders:",
            messageTwo: " Creating marketing campaigns that effectively promote IPs to target audience.",
            messageThreeTitle: "For Brands:",
            messageThree: " Designing comprehensive marketing strategies that align with brand goals and drive consumer engagement.",
            asset: Thirddd,
            assetType: 'image'
        },
        {
            title: "Cross-Brand Partnerships and Alliances",
            subtitle: "Connecting Brands for Enhanced Market Impact",
            messageOneTitle: "Strategic Collaborations:",
            messageOne: " Identifying and facilitating mutually beneficial partnerships.",
            messageTwoTitle: "Campaign Development:",
            messageTwo: " Developing and executing joint marketing campaigns.",
            messageThreeTitle: "Relationship Management:",
            messageThree: " Managing ongoing partnerships to ensure sustained success and growth.",
            asset: Thirdd,
            assetType: 'video'
        },
        {
            title: "Sponsorship Seeking and Management",
            subtitle: "Expert Strategies for Securing and Maximizing Sponsorships",
            messageOneTitle:"For Events:",
            messageOne: " Securing sponsorship deals that enhance the prestige and reach of events and also to create ideas to help brands milk sponsorships as much as possible.",
            messageTwoTitle:"For IP Holders:",
            messageTwo: " Identifying and negotiating sponsorship opportunities.",
            messageThreeTitle:"For Nations and Tournaments:",
            messageThree: " Developing sponsorship strategies that attract global brands.",
            asset: Fourth,
            assetType: 'video'
        },
        {
            title: "Capacity Enhancement for Brand Managers",
            subtitle: "Empowering Brand Managers for Maximum Impact",
            messageOneTitle:"Training Programs:",
            messageOne: " Offering specialized training to brand managers across Africa.",
            messageTwoTitle:"Workshops and Seminars:",
            messageTwo: " Conducting workshops and seminars on the latest marketing trends.",
            messageThreeTitle:"Consultative Support:",
            messageThree: " Providing ongoing support and mentorship.",
            asset: Fifth,
            assetType: 'video'
        }
    ];

    return (
        <div>
            {ServicesData.map((item, index) => (
                <div key={index} className="flex flex-col sticky top-0 z-20 md:flex-row justify-between items-start pt-[4rem]  pb-24 md:px-10 lg:px-10 px-5 w-full">
                    <div className="frame_50 flex flex-col h-[390px] md:h-[567px] lg:h-[600px] items-start gap-5 self-stretch p-4 md:p-4 lg:p-8 rounded-[1.25rem] bg-[#363134]">
                        <div className="flex flex-col items-start gap-3 self-stretch">
                            <div className="self-stretch text-[#d9d9d9] font-['Epilogue'] pt-1 lg:pt-2 md:pt-8 font-bold leading-[normal]">{item.title}</div>
                            <div className="self-stretch text-[#9e9898] font-['Epilogue'] text-lg md:text-md lg:text-2xl font-light leading-[130%]">{item.subtitle}</div>
                        </div>
                        <div className="self-stretch text-[#9e9898]   font-['Epilogue'] font-light text-sm md:text-md lg:text-lg leading-[170%]">
                        <p className="flex flex-row md:mt-5">
                        <p><span class=" text-[#d9d9d9] ">{item.messageOneTitle}</span> {item.messageOne}</p>
                            </p>
                            <p className="flex flex-row mt-2 md:mt-5">
                            <p><span class=" text-[#d9d9d9]">{item.messageTwoTitle}</span> {item.messageTwo}</p>
                            </p>
                            <p className="flex flex-row mt-2 md:mt-5">
                            <p><span class=" text-[#d9d9d9] ">{item.messageThreeTitle}</span> {item.messageThree}</p>
                            </p>
                        </div>
                    </div>
                    <div className="flex-shrink-0 w-full md:w-[60%] h-[300px]  md:h-[567px] lg:h-[600px] rounded-[1.25rem] bg-[#444040]">
                        {item.assetType === 'video' ? (
                            <video
                                className="md:w-auto w-full h-full object-cover pointer-events-none rounded-[1.25rem]"
                                src={item.asset}
                                autoPlay
                                loop
                                muted
                                playsInline
                            />
                        ) : (
                            <img
                                className="md:w-auto w-full h-full object-cover rounded-[1.25rem]"
                                src={item.asset}
                                alt={item.title}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Services;
