import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import { Element } from "react-scroll";
import HeroCard from "../Components/hero/HeroCard";
import Services from "../Components/services/Services";
import "../App.css"

const Landingpage = () => {
  return (
    <>
      <div className="sticky top-0 z-30">
        <Navbar />
      </div>
      <HeroCard />
      <Element name="services" >
        <Services />
      </Element>
    </>
  );
};

export default Landingpage;
