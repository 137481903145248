import React from "react";
import "./button.css";

const Button = () => {
  return (
    <div>
      <div class="Container Animate">
        <button
          class="Button"
          
        >
          <span>Let's Vibe</span>
         
        </button>
      </div>
    </div>
  );
};

export default Button;
