import React from 'react';
import hero from '../../assets/videos/barkingcathero.webm';
import Button from '../button/Button';
import { Link as ScrollLink } from 'react-scroll';
import "../../App.css"

const HeroCard = () => {
  return (
    <div className="relative flex items-center font-epilogue justify-center min-h-screen overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover pointer-events-none"
        src={hero}
        autoPlay
        loop
        muted
        playsInline
        preload='auto'
      />

      <div className="relative z-10 w-[90%] max-w-[754px] h-auto lg:h-[425px] bg-[#201A1ECC] backdrop-blur-sm border border-[#E2E6E7] mt-10 p-[20px] lg:p-[30px] gap-[20px] lg:gap-[40px] flex flex-col items-center justify-center">
        <div className="w-full h-full text-left  flex flex-col items-center justify-center gap-[20px] lg:gap-[30px] lg:text-center">
          <p className="text-[#E2E6E7] text-xs lg:text-sm font-bold">
            Creating synergistic relationships that drive value for brands, talents, and IP holders
          </p>
          <h2 className="text-[#E2E6E7] font-light text-3xl text-left md:text-center sm:text-4xl md:text-6xl lg:text-6xl ">
            <span>Empowering Pur-fect</span><br />
            <span>Partnerships,</span><br />
            <span>Amplifying Success</span>
          </h2>
          <ScrollLink 
                to="contact" 
                spy={true} 
                smooth={true} 
                offset={-70} 
                duration={500}
                className="text-[#E2E6E7]  hover:text-gray-500/75 cursor-pointer w-full"
              >
                <Button />
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
