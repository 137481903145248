import {RouterProvider, createBrowserRouter} from "react-router-dom"
import './App.css';
import Landingpage from "./pages/Landingpage";

const router = createBrowserRouter ([
  {path: "/", element: <Landingpage/>},
  



]);


const App = () => {
  return <RouterProvider router={router} />;
};


export default App;
